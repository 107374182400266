export const defaults = {
    page_title: "Moldova Nouă. Мы рассказываем то, что действительно важно. - Телеграм.SITES",
    main_url: "https://tracker.moldovanoua-telegram.com/tracking202/redirect/go.php?acip=219",
    name: "Moldova Nouă",
    tag: "@mol_noua",
    main_avatar_url: "/img/1.jpg",
    parsers:[
        {
            name: "Stop EU | Moldova",
            parser: "MOL_NOA",
            tag: "@mol_noua",
            main_url: "https://tracker.moldovanoua-telegram.com/tracking202/redirect/go.php?acip=219",
            avatar_url: "/img/1.jpg",
            contacts_phrase: "Непрочитанные сообщения",
            new_messages: 6
        },
    ],
    posts: []
}
