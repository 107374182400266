import {useEffect} from "react";
import PostItemSubscribe from "./PostItemSubscribe";

function PostItem(props) {

    useEffect(() => {
        const script_base = document.createElement("script")
        script_base.async = true
        script_base.src = "https://telegram.org/js/telegram-widget.js?22"
        script_base.setAttribute("id", props.url.replace("https://t.me/", ""))
        script_base.setAttribute("data-telegram-post", props.url.replace("https://t.me/", ""))
        script_base.setAttribute("data-width", "100%")
        script_base.setAttribute("data-userpic", "false")
        script_base.setAttribute("data-dark", "0")
        document.getElementById(props.url).appendChild(script_base)

        // console.log(document.querySelector("span").height())
        // const script = document.createElement('iframe');
        // script.setAttribute("data-telegram-post", props.url.replace("https://t.me/",""))
        // script.setAttribute("id", props.url)
        // script.setAttribute("src", props.url + "?embed=1&amp;dark=0")
        // script.setAttribute("width", "100%")
        // script.setAttribute("frameBorder", "0")
        // script.setAttribute("scrolling", "no")
        // script.setAttribute("style", "overflow: hidden; color-scheme: light; border: none; min-width: 320px;")
        // document.getElementById(props.url).appendChild(script)

    }, []);


    return (<div>
        <div id={props.url}>

        </div>
        <PostItemSubscribe url={props.url}/>
    </div>)

}

export default PostItem